<script>
import PromoCodeMixin from '~/mixins/promocode'
import { PAGES } from '~/utils/constants'

export default {
  mixins: [PromoCodeMixin],
  setup() {
    const { $label } = useNuxtApp()

    useHead({
      title: 'Inloggen • Bookchoice',
      meta: [
        {
          name: 'description',
          content: $label('user.signIn.subtitle'),
        },
      ],
    })
    definePageMeta({
      layout: 'stripped',
      middleware: ['guest'],
    })
  },
  computed: {
    routeId() {
      return this.$route.query && this.$route.query.id
    },
    nextPagePath() {
      if (this.routeId) {
        return PAGES.subscriptionChangeOverview
      }

      return PAGES.chooseSubscription
    },
  },
}
</script>

<template>
  <div data-nw="b2c-flow-login-page">
    <BaseLogin
      is-heading-shown
      :next-page-path="nextPagePath"
      register-path="aanmelden"
    />
  </div>
</template>
